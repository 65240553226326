import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { LandbotIds, OnboardLandbot } from '@lp-root/src/components/mol.onboard-landbot/onboard-landbot.component';
import { PageProps } from '@lp-src/utils/local-types';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';

const ExemploRelatorioPage: React.FunctionComponent<PageProps> = (props) => {
  const socialMedia = {
    canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
    title: 'Exemplo de relatório do diário do sono | Vigilantes do Sono',
    description: 'Confira um exemplo de relatório gerado a partir dos diários do sono preenchidos.',
  };
  return (
    <>
      <SEO socialMedia={socialMedia} />
      <OnboardLandbot landbot={LandbotIds.diary} />
    </>
  );
};

export default ExemploRelatorioPage;

export const query = graphql`
  query ExemploRelatorioQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
