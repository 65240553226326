import { ScriptLoader } from '@web/atomic/legacy/obj.script-loader';
import QueryString from 'querystringify';
import UtmDataSource from '@lp-root/src/data/utm.datasource';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LightColor } from '@web/atomic';
import { hasWindow } from '@web/utils/platform';

export enum LandbotIds {
  start = 'H-415681-52E3HKDOMY96ULTB',
  diary = 'H-498899-SLUULR0F0UKEH0SL',
}

interface IOnboardLandbotProps {
  // Landbot id (ex: "H-415681-52E3HKDOMY96ULTB")
  landbot: string;
}

export const OnboardLandbot: React.FunctionComponent<IOnboardLandbotProps> = (props) => {
  return (
    <ScriptLoader attributes={{ SameSite: 'None; Secure' }} src="https://static.landbot.io/landbot-widget/landbot-widget-1.0.0.js">
      {(attr) => {
        return <OnboardLandbotUI loaded={attr.done} landbot={props.landbot} />;
      }}
    </ScriptLoader>
  );
};

interface IOnboardLandbotUIProps {
  loaded: boolean;
  landbot: string;
}

// available after landbot scrip loading
/** https://dev.landbot.io/docs/?api=api_landbot_frame */
declare const LandbotFrameWidget;
/** https://dev.landbot.io/docs/?api=api_landbot_core */
// declare const LandbotAP

const OnboardLandbotUI: React.FunctionComponent<IOnboardLandbotUIProps> = (props) => {
  const id = 'myLandbot';
  const loaded = props.loaded;
  const [bot, setBot] = useState(null);
  useEffect(() => {
    if (loaded) {
      const medium = UtmDataSource.medium;
      const source = UtmDataSource.source;
      const search = QueryString.stringify({ utm_medium: medium, utm_src: source });
      try {
        const newBot = new LandbotFrameWidget({
          container: `#${id}`,
          index: `https://landbot.io/u/${props.landbot}/index.html?${search}`,
        });
        setBot(newBot);
      } catch (error) {
        console.error('error', error);
        if (hasWindow()) window.location.reload();
      }
    }
    return () => {
      if (bot) {
        bot.destroy();
      }
    };
  }, [loaded]);
  return (
    <>
      <OnboardLandbotUIStyled id={id} />
    </>
  );
};

const OnboardLandbotUIStyled = styled.div`
  background: ${LightColor.Primary};
  width: 100%;
  height: 100vh;
`;
